.bill-management-btn {
  display: inline-block;
  margin-left: 20px;
  font-weight: 600;
  border: 0.5px solid #c8c8c8;
}

.calculation-title {
  font-size: 16px;
  font-weight: 700;
}

.calculation-value {
  font-size: 16px;
  margin-bottom: 0px;
}

.calculation-value-box {
  background-color: #c4c4c4;
  display: inline-block;
  padding: 8px;
  margin-left: 10px;
  border-radius: 5px;
}

.calculation-days-box {
  background-color: #c4c4c4;
  display: inline-block;
  padding: 8px;
  border-radius: 5px;
}

.calculation-editable-box {
  display: inline-block;
  margin-left: 10px;
}

.info-box {
  border: 1px solid #c8c8c8;
  padding: 8px;
  .left-container {
    margin-right: 40px;
  }
  p {
    margin-bottom: 3px;
  }
}
