@import './icons.scss';

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// // RTL Mode
// @import "./bootstrap.scss";
// @import "./app-rtl.scss";

// Light Theme
@import './bootstrap.scss';
@import './app.scss';
#sidebar-menu {
    ul {
        li {
            a {
                i {
                    min-width: 2rem;
                }
            }
        }
    }
}
.noti-icon .badge {
    left: 23px;
}
.dataTables_filter,
.dataTables_paginate {
    float: right;
}
.rdw-editor-main {
    border: 1px solid $gray-300;
    height: 239px;
}
.dz-message {
    text-align: center;
    padding: 30px;
}
// kanban board
.fcYNFc,
.sc-AxmLO.gmtmqV {
    background-color: $card-bg !important;
    margin: 0 20px 0 0 !important;
}
.task-box {
    border: 1px solid $border-color;
}
.react-datepicker-wrapper {
    width: 100% !important;
}
.ReactModal__Overlay {
    z-index: 1001 !important;
}
.chat-conversation .right .conversation-list {
    margin-right: 15px;
}
.external-event {
    &:hover {
        cursor: pointer;
    }
}
a:hover {
    cursor: pointer;
}
.rating-container {
    background-color: transparent !important;
}
.input-group-append {
    z-index: 0;
}
.input-color {
    color: $input-color !important;
}

.pagination > .pagination {
    flex-wrap: wrap;
    justify-content: center;
}

.textarea-styled,
.input-styled {
    display: block;
    width: 100%;
    padding: 0.47rem 0.75rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-styled {
    height: calc(1.5em + 0.94rem + 2px);
}

.textarea-styled {
    height: 200px;
}

.radio {
    input {
        margin-right: 20px;
    }
}

.sketch-picker {
    position: absolute;
    z-index: 1;
}
.rangeslider__fill {
    background-color: $primary !important;
}

// .custom-control-input {
//   position: absolute !important;
//   left: 0 !important;
//   z-index: -1 !important;
//   width: 1rem !important;
//   height: 1.10938rem !important;
//   opacity: 0 !important;
// }

.flatpickr-months .flatpickr-month {
    background: $primary !important;
}
.flatpickr-weekdays {
    background: $primary !important;
}
span.flatpickr-weekday {
    background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
    background: $primary !important;
}
span.flatpickr-weekday {
    color: $white !important;
}
.flatpickr-day.selected {
    background: $primary !important;
}

//chat style dot
// .conversation-list .dropdown .dropdown-toggle{
//   font-size: 18px !important;
//   padding: 4px !important;
//   color: #74788d !important;
// }

.bs-select select {
    width: auto !important;
}

//File-manager
.file-manager {
    .table td {
        padding: 0.35rem;
    }
}

//Authenication carousel
.slider_css {
    .slide {
        background: none !important;
    }

    // .control-dots {
    //   margin-top: 35px !important;
    // }

    .carousel-status,
    .control-next,
    .control-prev {
        display: none !important;
    }
}
.carousel {
    .control-dots {
        margin: -5px 0px;
    }
    .dot {
        background: #556ee6 !important;
        width: 9px !important;
        height: 9px !important;
    }
}

//Two verification Page
.verification {
    input {
        &:focus {
            border: none;
            outline: none !important;
        }
    }
}

.modal-dialog {
    max-width: 760px;
}

@media (max-width: 768px) {
    .verification {
        input {
            width: 40px !important;
        }
    }
}

@media (max-width: 320px) {
    .verification {
        input {
            width: 30px !important;
            height: 30px !important;
            font-size: 15px !important;
        }
    }
}

//Two verification Page - 2
.verification-2 {
    input {
        &:focus {
            border: none;
            outline: none !important;
        }
    }
}

@media (max-width: 768px) {
    .verification-2 {
        input {
            width: 40px !important;
            font-size: 14px;
        }
    }
}

@media (max-width: 320px) {
    .verification-2 {
        input {
            width: 30px !important;
            height: 35px !important;
            font-size: 15px !important;
        }
    }
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding-top: 0;
    padding-bottom: 0;
}

.MuiInputLabel-outlined {
    transform: translate(14px, 13px) scale(1) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.7) !important;
}
